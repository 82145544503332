<template>
  <v-container fluid class="down-top-padding">
    <v-snackbar top v-model="snackbar" timeout="2000"> {{ msg }} </v-snackbar>
    <AddAlbum v-if="authority.photo" @my-click="add($event)"></AddAlbum>
    <draggable
      v-model="albumList"
      @start="drag = true"
      @end="changeAlbumOrder"
      class="row"
      :disabled="!authority.photo"
    >
      <v-col cols="12" md="3" v-for="item in albumList" :key="item._id">
        <v-hover v-slot="{ hover }" open-delay="50">
          <v-card
            dark
            :elevation="hover ? 16 : 4"
            class="mx-auto"
            :class="[
              'animate__animated animate__fadeIn',
              { 'on-hover': hover },
            ]"
            max-width="400"
            @click="
              $router.push({ path: '/photo/detail', query: { id: item._id } })
            "
          >
            <v-img
              class="white--text"
              height="150px"
              :src="item.thumbnail ? item.thumbnail : ''"
            >
              <DeleteAlbum
                v-if="authority.photo"
                @my-click="deleteAlbum(item._id)"
              ></DeleteAlbum>
            </v-img>

            <v-card-title class="text-subtitle-1"
              >{{ item.title }}
            </v-card-title>
            <v-card-text class="text-right"
              >作成日：{{
                moment(item.date).format("YYYY年M月D日")
              }}</v-card-text
            >
          </v-card>
        </v-hover>
      </v-col>
    </draggable>
  </v-container>
</template>

<script>
import AddAlbum from "@/components/photo/AddAlbum";
import DeleteAlbum from "@/components/photo/DeleteAlbum";
import axios from "axios";
import draggable from "vuedraggable";
import moment from "moment";

export default {
  name: "PhotoPage",

  data: () => ({
    albumList: [],
    authority: "",
    msg: "",
    snackbar: false,
    moment: moment,
  }),
  components: { AddAlbum, DeleteAlbum, draggable },
  methods: {
    add(e) {
      axios
        .post(process.env.VUE_APP_API_URL + "/add-album", {
          title: e.title,
          date: e.date,
        })
        .then((response) => {
          if (response.data.result == "OK") {
            this.getAlbumList();
          }
        });
    },
    getAlbumList() {
      this.albumList.splice(0);
      axios.get(process.env.VUE_APP_API_URL + "/get-album").then((response) => {
        response.data.albumList.forEach((elem) => {
          this.albumList.push(elem);
        });
        this.authority = response.data.authority;
      });
    },
    deleteAlbum(_id) {
      axios
        .post(process.env.VUE_APP_API_URL + "/delete-album", { _id: _id })
        .then(() => {
          this.getAlbumList();
        });
    },
    changeAlbumOrder() {
      this.drag = false;
      axios
        .post(process.env.VUE_APP_API_URL + "/change-album-order", {
          data: this.albumList,
        })
        .then(() => {
          this.msg = "アルバムの表示順を変更しました";
          this.snackbar = true;
        });
    },
  },
  created() {
    this.getAlbumList();
  },
};
</script>
<style>
</style>