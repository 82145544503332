<template>
  <v-dialog v-model="dialog" persistent max-width="350">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">
       mdi-trash-can-outline
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="text-h5">  </v-card-title>
      <v-card-text
        >本当に削除しますか？</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> キャンセル </v-btn>
        <v-btn color="primary" text @click="send()"> はい </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    send() {
      this.$emit("my-click");
    },
  },
};
</script>