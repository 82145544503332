<template>
  <v-dialog v-model="dialog" persistent max-width="350">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        fab
        dark
        v-bind="attrs"
        v-on="on"
        class="fixed_btn"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-form v-model="valid" ref="form" @submit.prevent>
        <v-card-title class="text-h5"> アルバム作成 </v-card-title>
        <v-card-text
          ><v-text-field
            :rules="nameRules"
            v-model="album.title"
            label="アルバム名"
          ></v-text-field
        ></v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> キャンセル </v-btn>
        <v-btn color="primary" text @click="send()"> 作成 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      valid: false,
      nameRules: [(v) => !!v || "入力してください"],
      dialog: false,
      album: {
        title: "",
        date: "",
      },
    };
  },
  methods: {
    send() {
      if (this.$refs.form.validate()) {
        this.dialog = false;
        this.$emit("my-click", this.album).$nextTick(() => {
          this.album.title = "";
          this.$refs.form.reset();
        });
      }
    },
  },
};
</script>
<style>
.fixed_btn {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 10000;
}
</style>